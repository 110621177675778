//data-place="header" data-size="normal" 
function handleIntersection(entries) {
    entries.map((entry) => {
        if (entry.isIntersecting){
            if(entry.target.classList.contains('fixed-infographic-container')) {
                entry.target.classList.remove('fixed-infographic-container');
                let bannerPlace = entry.target.getAttribute('data-place');
                let bannerSize = entry.target.getAttribute('data-size');
                let currentRotation = parseInt(localStorage.getItem('rotation-'+bannerPlace+'-'+bannerSize));
                let maxRotation = parseInt(localStorage.getItem('max-rotation-'+bannerPlace+'-'+bannerSize));
                if (currentRotation && maxRotation){
                    if(currentRotation < maxRotation){
                        currentRotation += 1;
                    }else{
                        currentRotation = 1;
                    }
                }else{
                    currentRotation = 1;
                }
                let immediateShow = entry.target.classList.contains('player-mobile-banner');
                localStorage.setItem('rotation-'+bannerPlace+'-'+bannerSize, currentRotation);
                showScheduledBanner(bannerPlace, bannerSize, currentRotation).then((data) => {
                    localStorage.setItem('max-rotation-'+bannerPlace+'-'+bannerSize, data.maxRotation);
                    if(data.success){
                        if(data.showTimeout && !immediateShow){//iframe
                            setTimeout(function(){
                                entry.target.innerHTML = data.bannerHtml;
                            }, 2000);
                        }else{
                            entry.target.innerHTML = data.bannerHtml;
                        }
                        if(immediateShow){
                            document.getElementById('infographic-container').classList.remove('banner-margin');
                            document.getElementById('channel_image_title_container').classList.add('d-portrait-none');
                            setTimeout(() => {
                                document.getElementById('player_hide_banner').classList.remove('d-none');
                            }, 20000);
                        }
                    }else{
                        entry.target.parentElement.remove();
                    }
                })
                .catch((err) => {
                    console.log("error", err.message);
                });
            }else if(entry.target.classList.contains('infographic-container')) {
                entry.target.classList.remove('infographic-container');
                let placeId = entry.target.getAttribute('id');
                let bannerSize = entry.target.getAttribute('data-size');
                let maxRotation = entry.target.getAttribute('data-max-rotation');
                let currentRotation = parseInt(localStorage.getItem('rotation-'+placeId));
                if (currentRotation && maxRotation){
                    if(currentRotation < maxRotation){
                        currentRotation += 1;
                    }else{
                        currentRotation = 1;
                    }
                }else{
                    currentRotation = 1;
                }
                if(entry.target.getAttribute('data-'+currentRotation+'-banner')){
                    let bannerId = entry.target.getAttribute('data-'+currentRotation+'-banner');
                    localStorage.setItem('rotation-'+placeId, currentRotation);
                    showBanner(bannerId, bannerSize).then((data) => {
                        if(data.success){
                            if(data.showTimeout){//iframe
                                setTimeout(function(){
                                    entry.target.innerHTML = data.bannerHtml;
                                }, 2000);
                            }else{
                                entry.target.innerHTML = data.bannerHtml;
                            }
                        }else{
                            entry.target.parentElement.remove();
                        }
                    })
                    .catch((err) => {
                        console.log("error", err.message);
                    });
                }
            }
        }
    });
}
const observer = new IntersectionObserver(handleIntersection);

window.addEventListener("load", (event) => {
    //const bannerTimeout = setTimeout(function(){
        document.querySelectorAll('.infographic-container, .fixed-infographic-container').forEach((i) => {
            if (i) {
                observer.observe(i);
            }
        });
    //}, 100);
});


async function showScheduledBanner(bannerPlace, bannerSize, currentRotation) {
    let siteUrl = window.location.origin;

    const response = await fetch(siteUrl + '/banners/'+bannerPlace+'/'+bannerSize+'/'+currentRotation, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            "Content-Type": "application/json",
            "X-Requested-With": "XMLHttpRequest"
        },
    });

    if (response.status !== 200) {
        throw new Error("cannot fetch data");
    }

    let responseData = await response.json();

    return responseData;
}

async function showBanner(bannerId, bannerSize) {
    let siteUrl = window.location.origin;

    const response = await fetch(siteUrl + '/showBanner/'+bannerId+'/'+bannerSize, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            "Content-Type": "application/json",
            "X-Requested-With": "XMLHttpRequest"
        },
    });

    if (response.status !== 200) {
        throw new Error("cannot fetch data");
    }

    let responseData = await response.json();

    return responseData;
}
